import { get, post } from '@/utils/request'
// import 'es6-promise/auto'


/* 登录 */
export async function fetchLogin<T>(data: { password?:string,
  mobile?:string,
  timestamp?:number,
  sign?:string}): Promise<T> {
  const response = await post<T>({
    url: '/login',
    data
  });
  
  // Assuming response has a property `data` that contains the desired result.
  return response.data; 
}

/* 团队||角色成员列表 */
export async function fetchTeamList<T>(curr:number,data: { page?:number,
  limit?:number,
  timestamp?:number,
  sign?:string}): Promise<T> {
  const response = await post<T>({
    url: curr===1?'/team/user/list':'/team/role/list',
    data
  });

  // Assuming response has a property `data` that contains the desired result.
  return response.data;
}
/*addUser*/
export async function addUser<T>(data: any): Promise<T> {
  const response = await post<T>({
    url: '/team/user/add',
    data
  });

  // Assuming response has a property `data` that contains the desired result.
  return response.data;
}
export async function updateUser<T>(data: any): Promise<T> {
  const response = await post<T>({
    url: '/team/user/edit',
    data
  });

  // Assuming response has a property `data` that contains the desired result.
  return response.data;
}
export async function delUser<T>(data: any): Promise<T> {
  const response = await post<T>({
    url: '/team/user/del',
    data
  });

  // Assuming response has a property `data` that contains the desired result.
  return response.data;
}

/*addRole*/
export async function addRole<T>(data: any): Promise<T> {
  const response = await post<T>({
    url: '/team/role/add',
    data
  });

  // Assuming response has a property `data` that contains the desired result.
  return response.data;
}
export async function updateRole<T>(data: any): Promise<T> {
  const response = await post<T>({
    url: '/team/role/edit',
    data
  });

  // Assuming response has a property `data` that contains the desired result.
  return response.data;
}
export async function delRole<T>(data: any): Promise<T> {
  const response = await post<T>({
    url: '/team/role/del',
    data
  });

  // Assuming response has a property `data` that contains the desired result.
  return response.data;
}
export async function getAuth<T>(data: any): Promise<T> {
  const response = await post<T>({
    url: '/team/access/list',
    data
  });

  // Assuming response has a property `data` that contains the desired result.
  return response.data;
}
export async function updateAuth<T>(data: any): Promise<T> {
  const response = await post<T>({
    url: '/team/access/save',
    data
  });

  // Assuming response has a property `data` that contains the desired result.
  return response.data;
}
export async function getUserInfo<T>(data: any): Promise<T> {
  const response = await post<T>({
    url: '/user/info',
    data
  });

  // Assuming response has a property `data` that contains the desired result.
  return response.data;
}
export async function updateUserInfo<T>(data: any): Promise<T> {
  const response = await post<T>({
    url: '/user/edit',
    data
  });

  // Assuming response has a property `data` that contains the desired result.
  return response.data;
}
export async function updateIndustry<T>(data: any): Promise<T> {
  const response = await post<T>({
    url: '/enterprise/industry',
    data
  });

  // Assuming response has a property `data` that contains the desired result.
  return response.data;
}
export async function logoutUser  <T>(data: any): Promise<T> {
  const response = await get<T>({
    url: '/user/logout',
    data
  });

  // Assuming response has a property `data` that contains the desired result.
  return response.data;
};
enum notifyType{
  all = 1,
  unread = 2,
}
interface notifyPostData {
  page: number,
  limit: number,
  timestamp: number,
  sign: number,
  type: notifyType
}
export async function notifyApi(data: notifyPostData){
  const response = await post({
    url: '/notify',
    data
  });
  
  return response.data.data;
}

export async function notifyReadApi(data: {notify_id: string, timestamp: number, sign: number}){
  const response = await post({
    url: '/notify/read',
    data
  });
  
  return response.data.data;
}