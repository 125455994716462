import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, unref as _unref, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "overflow-hidden layouMain mainBackColor",
  style: {"height":"100%"}
}

import { NSpace, NLayout, NLayoutSider, NMenu, NLayoutContent } from 'naive-ui'
// import type { Component } from 'vue'
// import { defineComponent, h, ref } from 'vue'
// import { NIcon } from 'naive-ui'
import SiderBar from '@/components/SiderBar.vue'
import HeaderCom from '@/components/HeaderCom.vue'
import Footers from '@/components/Footer.vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_RouterView = _resolveComponent("RouterView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(HeaderCom),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(SiderBar),
      _createVNode(_unref(NLayoutContent), { class: "h-full" }, {
        default: _withCtx(() => [
          _createVNode(_component_RouterView, { style: {"height":"calc(100% - 124px)","padding":"20px","overflow-y":"scroll","box-sizing":"border-box"} }, {
            default: _withCtx(({ Component, route }) => [
              (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
                key: route.fullPath
              }))
            ]),
            _: 1
          }),
          _createVNode(Footers)
        ]),
        _: 1
      })
    ])
  ], 64))
}
}

})