import axios, { type AxiosResponse } from 'axios'
import { useAuthStore } from '@/store'
import { useStore } from "vuex";
import { ss } from '@/utils/storage'
// const baseURL = 'https://result.eolink.com/YckmPrlc2c9f6992aa509335f1c5f4efdf9d7e9c920c55f?uri=/api'
const baseURL = 'https://api.panomobi.com/api'
//const baseURL = 'http://192.168.112.8:8126/api'
// const store = useStore();
;
const service = axios.create({
//   baseURL: import.meta.env.VITE_GLOB_API_URL,
  baseURL: baseURL,
  timeout: 2400 * 1000,
})
// const authStore = useAuthStore()
service.interceptors.request.use(
  (config) => {
    // const token = useAuthStore().token;
    const token = localStorage.getItem('token')
    // const uuid = useAuthStore().guestid;
    const uuid = ss.get('uuidToken'); 
    // console.log(uuid)
    if (token){

      config.headers.token = `${token}`
      
    }
    return config
  },
  (error) => {
    return Promise.reject(error.response)
  },
)

service.interceptors.response.use(
  (response: AxiosResponse): AxiosResponse => {
    if ([200, 201].includes(response.status))
      return response

    throw new Error(response.status.toString())
  },
  (error) => {
    return Promise.reject(error)
  },
)

export default service
