<script setup lang="ts">
import { NSpace, NLayout, NLayoutSider, NMenu, NBreadcrumb, NBreadcrumbItem } from 'naive-ui'
import type { Component } from 'vue'
import { defineComponent, h, ref, onMounted, onUnmounted } from 'vue'
import { NIcon } from 'naive-ui'
import { UserOutlined, BellOutlined } from '@ant-design/icons-vue';
import { useRouter, useRoute } from 'vue-router'
import { getCurrentTimestamp } from "@/utils/solt/timestamp";
import { sortByMd5WithSalt } from "@/utils/solt";
import { fetchApplyAccount } from "@/api/apply";
import { message, notification, Button } from "ant-design-vue";
import { NPagination } from 'naive-ui';
import { logoutUser, notifyApi, notifyReadApi } from "@/api/user";
import { Item } from 'ant-design-vue/es/menu';
const router = useRouter()
const route = useRoute()
const isDropdownVisible = ref(false)
function showDropdown() {
  isDropdownVisible.value = true;
}
function hideDropdown() {
  isDropdownVisible.value = false;
}
function viewProfile() {
  router.push('/User')
}
async function logout() {
  const time = await getCurrentTimestamp()
  const obj = {
    timestamp: time
  }
  const sign = await sortByMd5WithSalt(obj)
  const res = await logoutUser({ ...obj, sign })
  if (res?.code == 200) {
    message.success(res?.msg)
    localStorage.removeItem('token')
    localStorage.removeItem('UserInfo')
    router.push('/login')
  } else {
    message.info(res?.msg)
  }
}
const menuList = ref({
  index: ['首页'], countApply: ['拿账户', '开户申请'], countProgress: ['拿账户', '开户进度'], CountList: ['拿账户', '账户列表'], chargeProgress: ['管账户', '充值进度'], ChargeRecord: ['管账户', '充值记录'], Wallet: ['管资金', '钱包流水'], WalletProgress: ['管资金', '钱包充值进度'], WalletRecords: ['管资金', '账单管理'],
  ChargeReport: ['管账户', '账户消耗报告'], CompanyList: ['企业信息', '企业列表'], Team: ['企业信息', '团队管理'], CompanyInfo: ['企业信息'], User: ['帮助中心', '我的资料']
});


const showNotify = ref(false);
const unReadResult = ref({
  all_count: 1
});
const currentUnReadPage = ref(1);
function openNotificationWithIcon(item) {
  notification["info"]({
    message: item.title,
    description: item.content,
    btn: !unReadResult.value.unread?.find(unreadItem => unreadItem.notify_id == item.notify_id) ? null : h(
      Button,
      {
        type: 'primary',
        size: 'small',
        duration: 0,
        onClick: async () => {
          const time = await getCurrentTimestamp();
          const sign = await sortByMd5WithSalt({ timestamp: time });
          notifyReadApi({ notify_id: item.notify_id, timestamp: time, sign }).then(result => {
            notification.close(item.notify_id);
            getNotify();
          });
        },
      },
      '已读',
    ),
    key: item.notify_id,
    onClose: () => notification.close(item.notify_id),
  });
};
const box = ref(null)
const handleClickOutside = (event) => {
  if (box.value && !box.value.contains(event.target)) {
    isShowInfo.value = false;
  }
}
onMounted(() => {
  initNotify();
  setInterval(() => {
    initNotify();
  }, 900000);
  document.addEventListener('click', handleClickOutside)
});
onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside)
})
async function initNotify() {
  const time = await getCurrentTimestamp();
  const sign = await sortByMd5WithSalt({ timestamp: time });

  notifyApi({ page: 1, limit: 10, timestamp: time, sign, type: 2 }).then((result) => {
    unReadResult.value = result;
    showNotify.value = true;
    unReadResult.value.unread.forEach(element => {
      openNotificationWithIcon(element);
    });
  });
}

const isShowInfo = ref(false);
const activeKey = ref("all");
const allNotifyResult = ref({
  all_count: 1
});
const currentAllReadPage = ref(1);
function changeShowInfo() {
  isShowInfo.value = !isShowInfo.value;
  activeKey.value = "all";
  if (isShowInfo.value) getNotify("all");
};
function handleChangeActive(activeKey) {
  console.log(activeKey)
  allNotifyResult.value.all = null;
  currentAllReadPage.value = 1;
  unReadResult.value.unread = null;
  currentUnReadPage.value = 1;
  if (activeKey) getNotify(activeKey);
};
async function getNotify(activeKey) {
  const time = await getCurrentTimestamp();
  const sign = await sortByMd5WithSalt({ timestamp: time });

  notifyApi({ page: activeKey == "all" ? currentAllReadPage.value : currentUnReadPage.value, limit: 10, timestamp: time, sign, type: activeKey == "all" ? 1 : 2 }).then((result) => {
    activeKey == "all" ? allNotifyResult.value = result : unReadResult.value = result;
  });
};
function handleChangeAllPagination(page) {
  allNotifyResult.value.all = null;
  currentAllReadPage.value = page;
  getNotify("all");
};
function handleChangeUnReadPagination(page) {
  unReadResult.value.unread = null;
  currentUnReadPage.value = page;
  getNotify("read");
};
</script>

<template>
  <div class="mainBackColorHeader">
    <div style="display: flex;align-items: center;width: 220px;font-size: 25px;font-weight: bold;justify-content: center">
      <img src="@/assets/logo.png" style="object-fit: contain;height: 60px;margin-right: 20px;" />
      <div>PanoMobi</div>
    </div>
    <div class="main_title">
      <n-breadcrumb>
        <n-breadcrumb-item v-for="(item, i) in menuList[route.name]" :key="i">
          {{ item }}
        </n-breadcrumb-item>
      </n-breadcrumb>
    </div>
    <div style="display: flex;position: absolute;right: 0px;">
      <div style="position: relative;" ref="box" v-if="showNotify">
        <a-badge style="position: absolute;right: 0;margin-top: 5px;margin-right: 5px;cursor: pointer;" :count="unReadResult.unread_count" @click="changeShowInfo">
          <BellOutlined style="font-size: 24px;margin-top: 12px;margin-right: 5px;"></BellOutlined>
        </a-badge>
        <div v-show="isShowInfo" style="position: absolute; top:55px; right: 10px; width: 350px; background: #fff; border-radius: 5px; padding: 10px 20px;">
          <a-tabs v-model:activeKey="activeKey" @change="handleChangeActive(activeKey)">
            <a-tab-pane key="all" tab="全部">
              <div style="max-height: 400px; overflow-y: auto;">
                <div class="notify" style="cursor: pointer;padding: 5px 10px;" v-if="allNotifyResult && allNotifyResult.all" v-for="allItem in allNotifyResult.all" @click="openNotificationWithIcon(allItem)">
                  <p style="font-weight: bold;" :style="{ color: !allItem.status ? '#1677ff' : '#000' }">{{ allItem.title }}</p>
                  <p style="margin-bottom: 0;" :style="{ color: !allItem.status ? '#1677ff' : '#000' }">{{ allItem.create_time }}</p>
                </div>
                <div style="display: flex; justify-content: center;" v-else>
                  <a-spin />
                </div>
                <n-pagination style="display: flex; justify-content: center;" v-model:page="currentAllReadPage" :page-count="allNotifyResult.all_count / 10" @update:page="handleChangeAllPagination" :page-slot="5" />
              </div>
            </a-tab-pane>
            <a-tab-pane key="read" tab="未读" force-render>
              <div style="max-height: 400px; overflow-y: auto;">
                <div class="notify" style="cursor: pointer;padding: 5px 10px;max-height: 300px; overflow-y: auto;" v-if="unReadResult && unReadResult.unread" v-for="unreadItem in unReadResult.unread" @click="openNotificationWithIcon(unreadItem)">
                  <p style="font-weight: bold; color: #1677ff;">{{ unreadItem.title }}</p>
                  <p style="color: #1677ff;">{{ unreadItem.create_time }}</p>
                </div>
                <div style="display: flex; justify-content: center;" v-else>
                  <a-spin />
                </div>
                <n-pagination style="display: flex; justify-content: center;" v-model:page="currentUnReadPage" :page-count="unReadResult.unread_count / 10" @update:page="handleChangeUnReadPagination" :page-slot="5" />
              </div>
            </a-tab-pane>
          </a-tabs>
        </div>
      </div>
      <div class="use_info" @mouseenter="showDropdown" @mouseleave="hideDropdown">
        <UserOutlined /><!--@click="jumpLogin"-->
        <div v-if="isDropdownVisible" class="dropdown" @mouseenter="showDropdown" @mouseleave="hideDropdown">
          <ul>
            <li @click="viewProfile">我的资料</li>
            <li @click="logout">账号退出</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.mainBackColorHeader {
  display: flex;
  width: 100%;
  height: 64px;
}

.notify:hover {
  background-color: #f8f8f8;
}

.use_info {
  position: relative;
  top: 12px;
  right: 5rem;
  z-index: 99;
  border: 1px solid #aaa;
  border-radius: 50%;
  padding: 5px;
  width: 35px;
  height: 35px;
  background: #1981fe;
  cursor: pointer;
  color: #ffffffd1;
  text-align: center;
}

.main_title {
  transition: color 0.2s;
  padding: 0 4px;
  border-radius: 4px;
  height: 22px;
  display: inline-block;
  margin-inline: -4px;
  font-weight: 700;
  font-size: 1rem;
  color: black;
  line-height: 64px;
  margin-left: 15px;
}

/deep/ nav {
  padding: 30px 0px;
}

.dropdown {
  position: absolute;
  top: 29px;
  left: -40px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 10;
  border-radius: 4px;
  margin-top: 5px;
  width: 93px;
  color: #000000e0;
}

.dropdown ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown li {
  padding: 10px 15px;
  cursor: pointer;
}

.dropdown li:hover {
  color: #000000e0;
  background-color: #f5f5f5;
}
</style>
