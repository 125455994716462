import { get, post } from '@/utils/request'
// import 'es6-promise/auto'


/* 首页数据 */
export async function fetchInitData<T>(data: { timestamp?:number,
    sign?:string,
}): Promise<T> {
  const response = await post<T>({
    url: '/basic',
    data
  });
  
  // Assuming response has a property `data` that contains the desired result.
  return response.data; 
}
/* 企业公司数据 */
export async function fetchCompanyData<T>(data: { timestamp?:number,
    sign?:string,
}): Promise<T> {
    const response = await post<T>({
        url: '/company/list',
        data
    });

    // Assuming response has a property `data` that contains the desired result.
    return response.data;
}
/* 开户申请列表 */
export async function countProgress<T>(data: { timestamp?:number,
    page?:number,
    size?:number,
    sign?:string,
}): Promise<T> {
  const response = await post<T>({
    url: '/account/progress',
    data
  });
  
  // Assuming response has a property `data` that contains the desired result.
  return response.data; 
}

/* 开户申请 */
export async function fetchApplyAccount<T>(data: any): Promise<T> {
    const response = await post<T>({
        url: '/account/apply',
        data
    });

    // Assuming response has a property `data` that contains the desired result.
    return response.data;
}
/* 修改账户名称 */
export async function fetchEditAccount<T>(data: any): Promise<T> {
    const response = await post<T>({
        url: '/account/edit/name',
        data
    });

    // Assuming response has a property `data` that contains the desired result.
    return response.data;
}
/* 充值进度 */
export async function fetchRechargeProgressAccount<T>(data: any): Promise<T> {
    const response = await post<T>({
        url: '/account/recharge_progress',
        data
    });

    // Assuming response has a property `data` that contains the desired result.
    return response.data;
}
/* 操作记录 */
export async function fetchOperationList<T>(data: any): Promise<T> {
    const response = await post<T>({
        url: '/account/operation',
        data
    });

    // Assuming response has a property `data` that contains the desired result.
    return response.data;
}
/* 充值记录 */
export async function fetchRecharge_LogList<T>(data: any): Promise<T> {
    const response = await post<T>({
        url: '/account/recharge_log',
        data
    });

    // Assuming response has a property `data` that contains the desired result.
    return response.data;
}
/* 服务费流程||监管费流水 */
export async function fetchServiceList<T>(curr:number,data: any): Promise<T> {
    const response = await post<T>({
        url: curr===1?'/account/service':'/account/supervision',
        data
    });

    // Assuming response has a property `data` that contains the desired result.
    return response.data;
}
/* 钱包流水 */
export async function fetchFlowList<T>(data: any): Promise<T> {
    const response = await post<T>({
        url: '/capital/flow',
        data
    });

    // Assuming response has a property `data` that contains the desired result.
    return response.data;
}
/* 钱包充值进度 */
export async function fetchProgressList<T>(data: any): Promise<T> {
    const response = await post<T>({
        url: '/capital/progress',
        data
    });

    // Assuming response has a property `data` that contains the desired result.
    return response.data;
}
/* 账单管理 */
export async function fetchBillingList<T>(data: any): Promise<T> {
    const response = await post<T>({
        url: '/capital/billing',
        data
    });

    // Assuming response has a property `data` that contains the desired result.
    return response.data;
}
/* 消耗记录||汇总 */
export async function fetchConsumeList<T>(curr:number,data: any): Promise<T> {
    const response = await post<T>({
        url: curr===0?'/account/consume':'/account/consume/all',
        data
    });

    // Assuming response has a property `data` that contains the desired result.
    return response.data;
}

/* 企业消耗 */
export async function fetchCompanyConsume<T>(data: any): Promise<T> {
    const response = await post<T>({
        url: '/company/consume',
        data
    });

    // Assuming response has a property `data` that contains the desired result.
    return response.data;
}

/* 企业帐户统计 */
export async function companyAccount<T>(data: any): Promise<T> {
    const response = await post<T>({
        url: '/company/account',
        data
    });

    // Assuming response has a property `data` that contains the desired result.
    return response.data;
}

/* 公司列表 */
export async function fetchEnterpriseData<T>(data: any): Promise<T> {
    const response = await post<T>({
        url: '/enterprise/list',
        data
    });

    // Assuming response has a property `data` that contains the desired result.
    return response.data;
}
/* 企业入驻 */
export async function RegisterEnterpriseData<T>(data: any): Promise<T> {
    const response = await post<T>({
        url: '/register',
        data,
        // headers:{'content-type': 'application/x-www-form-urlencoded'}
    });

    // Assuming response has a property `data` that contains the desired result.
    return response.data;
}
/* 顾问列表 */
export async function fetchCounselorData<T>(data: any): Promise<T> {
    const response = await post<T>({
        url: '/counselor/list',
        data
    });

    // Assuming response has a property `data` that contains the desired result.
    return response.data;
}
/* MCC授权 */
export async function fetchMccData<T>(data: any): Promise<T> {
    const response = await post<T>({
        url: '/account/mcc',
        data,
        headers:
        {'Content-Type': 'application/x-www-form-urlencoded'}
    });

    // Assuming response has a property `data` that contains the desired result.
    return response.data;
}
/* email授权 */
export async function fetchEmailData<T>(data: any): Promise<T> {
    const response = await post<T>({
        url: '/account/email',
        data,
        headers:
        {'Content-Type': 'application/x-www-form-urlencoded'}
    });

    // Assuming response has a property `data` that contains the desired result.
    return response.data;
}
/* 账户充值1|账户减款2|账户清零3 */
export async function fetchMoneyData<T>(num:number,data: any): Promise<T> {
    const response = await post<T>({
        url: num===1?'/account/recharge':(num===2?'/account/deduction':'/account/reset'),
        data,
        headers:
            {'Content-Type': 'application/x-www-form-urlencoded'}
    });

    // Assuming response has a property `data` that contains the desired result.
    return response.data;
}
/* 充值基本信息 */
export async function fetchBasicMoneyData<T>(data: any): Promise<T> {
    const response = await post<T>({
        url: '/account/recharge/info',
        data,
        headers:
            {'Content-Type': 'application/x-www-form-urlencoded'}
    });

    // Assuming response has a property `data` that contains the desired result.
    return response.data;
}
/* 查询帐户余额 */
export async function fetchBalaceList<T>(data: any): Promise<T> {
    const response = await post<T>({
        url: '/account/balance',
        data,
        headers:
            {'Content-Type': 'application/x-www-form-urlencoded'}
    });

    // Assuming response has a property `data` that contains the desired result.
    return response.data;
}

/* 钱包充值 */
export async function fetchRemittanceData<T>(data: any): Promise<T> {
    const response = await post<T>({
        url: '/capital/remittance',
        data,
        // headers:{'content-type': 'application/x-www-form-urlencoded'}
    });

    // Assuming response has a property `data` that contains the desired result.
    return response.data;
}
/* MCC审核 */
export async function fetchAuditData<T>(data: any): Promise<T> {
    const response = await post<T>({
        url: '/audit/mcc',
        data,
        // headers:{'content-type': 'application/x-www-form-urlencoded'}
    });

    // Assuming response has a property `data` that contains the desired result.
    return response.data;
}
/* 帐户审核 */
export async function fetchAuditAccountData<T>(data: any): Promise<T> {
    const response = await post<T>({
        url: '/account/audit',
        data,
        // headers:{'content-type': 'application/x-www-form-urlencoded'}
    });

    // Assuming response has a property `data` that contains the desired result.
    return response.data;
}
/* 帐户批量审核 */
export async function fetchBatchAuditAccountData<T>(data: any): Promise<T> {
    const response = await post<T>({
        url: '/account/batch/audit',
        data,
        // headers:{'content-type': 'application/x-www-form-urlencoded'}
    });

    // Assuming response has a property `data` that contains the desired result.
    return response.data;
}

/* 帐户封户率查询 */
export async function fetchAuditShowData<T>(data: any): Promise<T> {
    const response = await post<T>({
        url: '/account/audit/show',
        data,
        // headers:{'content-type': 'application/x-www-form-urlencoded'}
    });

    // Assuming response has a property `data` that contains the desired result.
    return response.data;
}
/*账单回款录入*/
export async function billReturned<T>(data: any): Promise<T> {
    const response = await post<T>({
        url: '/capital/billing/returned',
        data,
        // headers:{'content-type': 'application/x-www-form-urlencoded'}
    });

    // Assuming response has a property `data` that contains the desired result.
    return response.data;
}
/* 获取菜单 */
export async function fetchMenuData<T>(data: any): Promise<T> {
    const response = await post<T>({
        url: '/index/menu',
        data,
        // headers:{'content-type': 'application/x-www-form-urlencoded'}
    });

    // Assuming response has a property `data` that contains the desired result.
    return response.data;
}
/* 账单确认 */
export async function billAffirm<T>(data: any): Promise<T> {
    const response = await post<T>({
        url: '/capital/billing/affirm',
        data,
        // headers:{'content-type': 'application/x-www-form-urlencoded'}
    });

    // Assuming response has a property `data` that contains the desired result.
    return response.data;
}
/* 账单导入 */
export async function billImport<T>(data: any): Promise<T> {
    const response = await post<T>({
        url: '/capital/billing/import',
        data,
        // headers:{'content-type': 'application/x-www-form-urlencoded'}
    });

    // Assuming response has a property `data` that contains the desired result.
    return response.data;
}