import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"
import _imports_0 from '@/assets/logo.png'


const _hoisted_1 = { class: "mainBackColorHeader" }
const _hoisted_2 = { class: "main_title" }
const _hoisted_3 = { style: {"display":"flex","position":"absolute","right":"0px"} }
const _hoisted_4 = { style: {"position":"absolute","top":"55px","right":"10px","width":"350px","background":"#fff","border-radius":"5px","padding":"10px 20px"} }
const _hoisted_5 = { style: {"max-height":"400px","overflow-y":"auto"} }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = {
  key: 1,
  style: {"display":"flex","justify-content":"center"}
}
const _hoisted_8 = { style: {"max-height":"400px","overflow-y":"auto"} }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { style: {"font-weight":"bold","color":"#1677ff"} }
const _hoisted_11 = { style: {"color":"#1677ff"} }
const _hoisted_12 = {
  key: 1,
  style: {"display":"flex","justify-content":"center"}
}

import { NSpace, NLayout, NLayoutSider, NMenu, NBreadcrumb, NBreadcrumbItem } from 'naive-ui'
import type { Component } from 'vue'
import { defineComponent, h, ref, onMounted, onUnmounted } from 'vue'
import { NIcon } from 'naive-ui'
import { UserOutlined, BellOutlined } from '@ant-design/icons-vue';
import { useRouter, useRoute } from 'vue-router'
import { getCurrentTimestamp } from "@/utils/solt/timestamp";
import { sortByMd5WithSalt } from "@/utils/solt";
import { fetchApplyAccount } from "@/api/apply";
import { message, notification, Button } from "ant-design-vue";
import { NPagination } from 'naive-ui';
import { logoutUser, notifyApi, notifyReadApi } from "@/api/user";
import { Item } from 'ant-design-vue/es/menu';

export default /*@__PURE__*/_defineComponent({
  __name: 'HeaderCom',
  setup(__props) {

const router = useRouter()
const route = useRoute()
const isDropdownVisible = ref(false)
function showDropdown() {
  isDropdownVisible.value = true;
}
function hideDropdown() {
  isDropdownVisible.value = false;
}
function viewProfile() {
  router.push('/User')
}
async function logout() {
  const time = await getCurrentTimestamp()
  const obj = {
    timestamp: time
  }
  const sign = await sortByMd5WithSalt(obj)
  const res = await logoutUser({ ...obj, sign })
  if (res?.code == 200) {
    message.success(res?.msg)
    localStorage.removeItem('token')
    localStorage.removeItem('UserInfo')
    router.push('/login')
  } else {
    message.info(res?.msg)
  }
}
const menuList = ref({
  index: ['首页'], countApply: ['拿账户', '开户申请'], countProgress: ['拿账户', '开户进度'], CountList: ['拿账户', '账户列表'], chargeProgress: ['管账户', '充值进度'], ChargeRecord: ['管账户', '充值记录'], Wallet: ['管资金', '钱包流水'], WalletProgress: ['管资金', '钱包充值进度'], WalletRecords: ['管资金', '账单管理'],
  ChargeReport: ['管账户', '账户消耗报告'], CompanyList: ['企业信息', '企业列表'], Team: ['企业信息', '团队管理'], CompanyInfo: ['企业信息'], User: ['帮助中心', '我的资料']
});


const showNotify = ref(false);
const unReadResult = ref({
  all_count: 1
});
const currentUnReadPage = ref(1);
function openNotificationWithIcon(item) {
  notification["info"]({
    message: item.title,
    description: item.content,
    btn: !unReadResult.value.unread?.find(unreadItem => unreadItem.notify_id == item.notify_id) ? null : h(
      Button,
      {
        type: 'primary',
        size: 'small',
        duration: 0,
        onClick: async () => {
          const time = await getCurrentTimestamp();
          const sign = await sortByMd5WithSalt({ timestamp: time });
          notifyReadApi({ notify_id: item.notify_id, timestamp: time, sign }).then(result => {
            notification.close(item.notify_id);
            getNotify();
          });
        },
      },
      '已读',
    ),
    key: item.notify_id,
    onClose: () => notification.close(item.notify_id),
  });
};
const box = ref(null)
const handleClickOutside = (event) => {
  if (box.value && !box.value.contains(event.target)) {
    isShowInfo.value = false;
  }
}
onMounted(() => {
  initNotify();
  setInterval(() => {
    initNotify();
  }, 900000);
  document.addEventListener('click', handleClickOutside)
});
onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside)
})
async function initNotify() {
  const time = await getCurrentTimestamp();
  const sign = await sortByMd5WithSalt({ timestamp: time });

  notifyApi({ page: 1, limit: 10, timestamp: time, sign, type: 2 }).then((result) => {
    unReadResult.value = result;
    showNotify.value = true;
    unReadResult.value.unread.forEach(element => {
      openNotificationWithIcon(element);
    });
  });
}

const isShowInfo = ref(false);
const activeKey = ref("all");
const allNotifyResult = ref({
  all_count: 1
});
const currentAllReadPage = ref(1);
function changeShowInfo() {
  isShowInfo.value = !isShowInfo.value;
  activeKey.value = "all";
  if (isShowInfo.value) getNotify("all");
};
function handleChangeActive(activeKey) {
  console.log(activeKey)
  allNotifyResult.value.all = null;
  currentAllReadPage.value = 1;
  unReadResult.value.unread = null;
  currentUnReadPage.value = 1;
  if (activeKey) getNotify(activeKey);
};
async function getNotify(activeKey) {
  const time = await getCurrentTimestamp();
  const sign = await sortByMd5WithSalt({ timestamp: time });

  notifyApi({ page: activeKey == "all" ? currentAllReadPage.value : currentUnReadPage.value, limit: 10, timestamp: time, sign, type: activeKey == "all" ? 1 : 2 }).then((result) => {
    activeKey == "all" ? allNotifyResult.value = result : unReadResult.value = result;
  });
};
function handleChangeAllPagination(page) {
  allNotifyResult.value.all = null;
  currentAllReadPage.value = page;
  getNotify("all");
};
function handleChangeUnReadPagination(page) {
  unReadResult.value.unread = null;
  currentUnReadPage.value = page;
  getNotify("read");
};

return (_ctx: any,_cache: any) => {
  const _component_a_badge = _resolveComponent("a-badge")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_a_tabs = _resolveComponent("a-tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[4] || (_cache[4] = _createElementVNode("div", { style: {"display":"flex","align-items":"center","width":"220px","font-size":"25px","font-weight":"bold","justify-content":"center"} }, [
      _createElementVNode("img", {
        src: _imports_0,
        style: {"object-fit":"contain","height":"60px","margin-right":"20px"}
      }),
      _createElementVNode("div", null, "PanoMobi")
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_unref(NBreadcrumb), null, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menuList.value[_unref(route).name], (item, i) => {
            return (_openBlock(), _createBlock(_unref(NBreadcrumbItem), { key: i }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(item), 1)
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      (showNotify.value)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            style: {"position":"relative"},
            ref_key: "box",
            ref: box
          }, [
            _createVNode(_component_a_badge, {
              style: {"position":"absolute","right":"0","margin-top":"5px","margin-right":"5px","cursor":"pointer"},
              count: unReadResult.value.unread_count,
              onClick: changeShowInfo
            }, {
              default: _withCtx(() => [
                _createVNode(_unref(BellOutlined), { style: {"font-size":"24px","margin-top":"12px","margin-right":"5px"} })
              ]),
              _: 1
            }, 8, ["count"]),
            _withDirectives(_createElementVNode("div", _hoisted_4, [
              _createVNode(_component_a_tabs, {
                activeKey: activeKey.value,
                "onUpdate:activeKey": _cache[2] || (_cache[2] = ($event: any) => ((activeKey).value = $event)),
                onChange: _cache[3] || (_cache[3] = ($event: any) => (handleChangeActive(activeKey.value)))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_tab_pane, {
                    key: "all",
                    tab: "全部"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_5, [
                        (allNotifyResult.value && allNotifyResult.value.all)
                          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(allNotifyResult.value.all, (allItem) => {
                              return (_openBlock(), _createElementBlock("div", {
                                class: "notify",
                                style: {"cursor":"pointer","padding":"5px 10px"},
                                onClick: ($event: any) => (openNotificationWithIcon(allItem))
                              }, [
                                _createElementVNode("p", {
                                  style: _normalizeStyle([{"font-weight":"bold"}, { color: !allItem.status ? '#1677ff' : '#000' }])
                                }, _toDisplayString(allItem.title), 5),
                                _createElementVNode("p", {
                                  style: _normalizeStyle([{"margin-bottom":"0"}, { color: !allItem.status ? '#1677ff' : '#000' }])
                                }, _toDisplayString(allItem.create_time), 5)
                              ], 8, _hoisted_6))
                            }), 256))
                          : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                              _createVNode(_component_a_spin)
                            ])),
                        _createVNode(_unref(NPagination), {
                          style: {"display":"flex","justify-content":"center"},
                          page: currentAllReadPage.value,
                          "onUpdate:page": [
                            _cache[0] || (_cache[0] = ($event: any) => ((currentAllReadPage).value = $event)),
                            handleChangeAllPagination
                          ],
                          "page-count": allNotifyResult.value.all_count / 10,
                          "page-slot": 5
                        }, null, 8, ["page", "page-count"])
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_tab_pane, {
                    key: "read",
                    tab: "未读",
                    "force-render": ""
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_8, [
                        (unReadResult.value && unReadResult.value.unread)
                          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(unReadResult.value.unread, (unreadItem) => {
                              return (_openBlock(), _createElementBlock("div", {
                                class: "notify",
                                style: {"cursor":"pointer","padding":"5px 10px","max-height":"300px","overflow-y":"auto"},
                                onClick: ($event: any) => (openNotificationWithIcon(unreadItem))
                              }, [
                                _createElementVNode("p", _hoisted_10, _toDisplayString(unreadItem.title), 1),
                                _createElementVNode("p", _hoisted_11, _toDisplayString(unreadItem.create_time), 1)
                              ], 8, _hoisted_9))
                            }), 256))
                          : (_openBlock(), _createElementBlock("div", _hoisted_12, [
                              _createVNode(_component_a_spin)
                            ])),
                        _createVNode(_unref(NPagination), {
                          style: {"display":"flex","justify-content":"center"},
                          page: currentUnReadPage.value,
                          "onUpdate:page": [
                            _cache[1] || (_cache[1] = ($event: any) => ((currentUnReadPage).value = $event)),
                            handleChangeUnReadPagination
                          ],
                          "page-count": unReadResult.value.unread_count / 10,
                          "page-slot": 5
                        }, null, 8, ["page", "page-count"])
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["activeKey"])
            ], 512), [
              [_vShow, isShowInfo.value]
            ])
          ], 512))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: "use_info",
        onMouseenter: showDropdown,
        onMouseleave: hideDropdown
      }, [
        _createVNode(_unref(UserOutlined)),
        (isDropdownVisible.value)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "dropdown",
              onMouseenter: showDropdown,
              onMouseleave: hideDropdown
            }, [
              _createElementVNode("ul", null, [
                _createElementVNode("li", { onClick: viewProfile }, "我的资料"),
                _createElementVNode("li", { onClick: logout }, "账号退出")
              ])
            ], 32))
          : _createCommentVNode("", true)
      ], 32)
    ])
  ]))
}
}

})