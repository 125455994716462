import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"display":"flex","align-items":"center"} }
const _hoisted_2 = { style: {"font-size":"16px","width":"230px","overflow":"hidden","text-overflow":"ellipsis","white-space":"nowrap"} }
const _hoisted_3 = { class: "team" }

import { NSpace, NLayout, NLayoutSider, NMenu } from 'naive-ui'
import type { Component } from 'vue'
import { defineComponent, h, onMounted, ref } from 'vue'
import { NIcon } from 'naive-ui'
import HeaderCom from '@/components/HeaderCom.vue'
import FooterCom from '@/components/FooterCom.vue'
import Footers from '@/components/Footer.vue'
import { useAuthStore } from '@/store'
import { useRouter, useRoute } from 'vue-router'
import {
  MailOutlined,
  CalendarOutlined,
  AppstoreOutlined,
  SettingOutlined,
  HomeOutlined,
  UserOutlined,
  EuroOutlined,
  InsertRowLeftOutlined
} from '@ant-design/icons-vue';
import { getCurrentTimestamp } from "@/utils/solt/timestamp";
import { sortByMd5WithSalt } from "@/utils/solt";
import { fetchMenuData, fetchProgressList } from "@/api/apply";

export default /*@__PURE__*/_defineComponent({
  __name: 'SiderBar',
  props: {
    msg: {}
  },
  setup(__props: any) {

const authStore = useAuthStore()
const router = useRouter()
const route = useRoute()
console.log(authStore.userInfo)
authStore.userInfo = JSON.parse(localStorage.getItem('UserInfo'))
async function getMenu() {
  const time = await getCurrentTimestamp()
  const obj = {
    timestamp: time
  }
  const sign = await sortByMd5WithSalt(obj)
  const res = await fetchMenuData({
    timestamp: time,
    sign: sign
  })
  let arr = []
  res?.data.forEach((item, index) => {
    if (item.children.length === 0) {
      delete item.children;
    }
    if (item.label === '首页') {
      item.icon = () => h(InsertRowLeftOutlined)
    } else if (item.label == '拿帐户') {
      item.icon = () => h(AppstoreOutlined)
    } else if (item.label == '管帐户') {
      item.icon = () => h(SettingOutlined)
    } else if (item.label == '管资金') {
      item.icon = () => h(EuroOutlined)
    }
    if (item.label == '企业信息') {
      // res?.data.splice(index,1)
      item.icon = () => h(InsertRowLeftOutlined)
      menuOptions1.value = [item];
    } else {
      arr.push(item)
    }
  })
  console.log("菜单列表", res?.data)
  menuOptions.value = arr
}
onMounted(() => {
  getMenu()
})
const menuOptions = ref([
  {
    key: 'index',
    icon: () => h(HomeOutlined),
    label: '首页',
    title: 'index',
  },
  {
    key: '2',
    icon: () => h(AppstoreOutlined),
    label: '拿账户',
    title: 'dance',
    children: [
      {
        key: 'countApply',
        label: '开户申请',
        title: 'countApply',
      },
      {
        key: 'countProgress',
        label: '开户进度',
        title: 'countProgress',
      },

    ],
  },
  {
    key: 'sub2',
    icon: () => h(SettingOutlined),
    label: '管账户',
    title: '管账户',
    children: [
      {
        key: 'CountList',
        label: '账户列表',
        title: 'countList',
      },
      {
        key: 'ChargeReport',
        label: '账户消耗报告',
        title: 'chargeReport',
      },
      {
        key: 'chargeProgress',
        label: '充值进度',
        title: 'chargeProgress',
      },
      {
        key: 'ChargeRecord',
        label: '充值记录',
        title: 'chargeRecords',
      }

    ],
  },
  {
    key: 'sub3',
    icon: () => h(EuroOutlined),
    label: '管资金',
    title: '管资金',
    children: [
      {
        key: 'Wallet',
        label: '钱包流水',
        title: 'wallet',
      },
      {
        key: 'WalletProgress',
        label: '钱包充值进度',
        title: 'walletProgress',
      },
      {
        key: 'WalletRecords',
        label: '账单管理',
        title: 'walletRecords',
      }

    ],
  },

]);
const menuOptions1 = ref([
  // {
  //   key: '33',
  //   icon: () => h(InsertRowLeftOutlined),
  //   label: '企业信息',
  //   title: 'compony',
  //   mode: "vertical",
  //   children: [
  //     {
  //         key: 'CompanyInfo',
  //         label: '企业信息',
  //         title: 'CompanyInfo',
  //     },
  //     {
  //         key: 'CompanyList',
  //         label: '企业列表',
  //         title: 'CompanyList',
  //     },
  //     {
  //       key: 'Team',
  //       label: '团队管理',
  //       title: 'Team',
  //     }
  //
  //
  //   ],
  // },
])
const selectedKeys = ref([route.name]);
const openKeys = ref(['index', '2', 'sub2', 'sub3']);

function renderIcon(icon: Component) {
  return () => h(NIcon, null, { default: () => h(icon) })
}


const count = ref(0)
function jumpLogin() {
  router.push('/login')
}

function getKeys(item: any) {
  router.push(item.key)
}

return (_ctx: any,_cache: any) => {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_menu = _resolveComponent("a-menu")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_button, { style: {"width":"200px","height":"50px","overflow":"hidden","text-overflow":"ellipsis","white-space":"nowrap","margin":"0 10px"} }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_unref(authStore)?.userInfo?.enterprise_name), 1)
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_a_menu, {
      openKeys: openKeys.value,
      "onUpdate:openKeys": _cache[0] || (_cache[0] = ($event: any) => ((openKeys).value = $event)),
      selectedKeys: selectedKeys.value,
      "onUpdate:selectedKeys": _cache[1] || (_cache[1] = ($event: any) => ((selectedKeys).value = $event)),
      style: {"font-size":"16px","height":"calc(100% - 48px - 50px)","overflow-y":"auto"},
      mode: "inline",
      items: menuOptions.value,
      onClick: getKeys
    }, null, 8, ["openKeys", "selectedKeys", "items"]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_a_menu, {
        forceSubMenuRender: true,
        openKeys: openKeys.value,
        "onUpdate:openKeys": _cache[2] || (_cache[2] = ($event: any) => ((openKeys).value = $event)),
        selectedKeys: selectedKeys.value,
        "onUpdate:selectedKeys": _cache[3] || (_cache[3] = ($event: any) => ((selectedKeys).value = $event)),
        style: {"font-size":"16px"},
        mode: "vertical",
        items: menuOptions1.value,
        onClick: getKeys
      }, null, 8, ["openKeys", "selectedKeys", "items"])
    ])
  ]))
}
}

})